<template>
  <div>
    <img src="@/assets/solutionpdf/jewelry_1.jpg" alt="" />
    <img src="@/assets/solutionpdf/jewelry_2.jpg" alt="" />
    <img src="@/assets/solutionpdf/jewelry_3.jpg" alt="" />
    <img src="@/assets/solutionpdf/jewelry_4.jpg" alt="" />
    <img src="@/assets/solutionpdf/jewelry_5.jpg" alt="" />
    <img src="@/assets/solutionpdf/jewelry_6.jpg" alt="" />
    <img src="@/assets/solutionpdf/jewelry_7.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: "jewelry-manage",
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>